import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { Container } from "react-bulma-components";
import AnchorLink from "react-anchor-link-smooth-scroll";

import "./Puffs.scss";

export default () => {
  return (
    <Container className="puff-container">
      <div className="puff">
        <AnchorLink href="#bracelets">
          <StaticImage style={{ width: "100%" }} alt="Banner armband" src="../../images/puff-bracelet.jpg" placeholder="blurred" width={455} height={224} />
        </AnchorLink>
      </div>
      <div className="puff">
        <AnchorLink href="#belts">
          <StaticImage style={{ width: "100%" }} alt="Banner skärp" src="../../images/puff-belt.jpg" placeholder="blurred" width={455} height={224} />
        </AnchorLink>
      </div>
      <div className="puff">
        <Link to="/blog/sjalvlysande-golfbollar">
          <StaticImage style={{ width: "100%" }} alt="Banner golfbollar" src="../../images/puff-golf.png" placeholder="blurred" width={455} height={224} />
        </Link>
      </div>
    </Container>
  );
};
