import React from "react";
import { graphql } from "gatsby";

import SEO from "../components/SEO";
import ProductList from "../components/ProductList";
import Puffs from "../components/Puffs";

const Index = ({ data }) => {
  let bracelets = data.airtable.edges.filter(({ node }) => node.data.Type === "Armband").map(({ node }) => ({ ...node.data, ...node.fields }));
  let belts = data.airtable.edges.filter(({ node }) => node.data.Type === "Skarp").map(({ node }) => ({ ...node.data, ...node.fields }));
  let misc = data.airtable.edges.filter(({ node }) => node.data.Type === "Prylar").map(({ node }) => ({ ...node.data, ...node.fields }));

  return (
    <>
      <SEO title="Wallang | Accessoarer och smarta prylar online" image={data.topImage.childImageSharp.gatsbyImageData} />
      <Puffs />
      <h1 style={{ display: "none" }}>Välkommen till Wallang! Accessoarer och smarta prylar</h1>
      <ProductList products={bracelets} title="Armband" scrollId="bracelets" />
      <ProductList products={belts} title="Skärp" scrollId="belts" />
      <ProductList products={misc} title="Prylar" scrollId="misc" />
    </>
  );
};

export const query = graphql`
  query {
    airtable: allAirtable(filter: { table: { eq: "Products" }, data: { Active: { eq: true } } }) {
      edges {
        node {
          data {
            Id
            Images {
              localFiles {
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
                }
              }
            }
            Active
            Name
            Price
            SalePrice
            Type
          }
          fields {
            Slug
          }
        }
      }
    }
    topImage: file(relativePath: { eq: "top-image.png" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }
  }
`;

export default Index;
